<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-user-lock"></i> ข้อมูลรายละเอียดจำนวนสินค้า </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-7 column">
                  <div class="row">
                    <div class="col-sm-5 mb-2"><strong>ประเภทรายงาน :</strong></div>
                    <div class="col-sm-7">{{reportData.reportTypeName}}</div>
                    <div class="col-sm-5 mb-2"><strong>แบ่งข้อมูล{{reportData.subTypeName}} :</strong></div>
                    <div class="col-sm-7">{{reportData.typeName}}</div>
                    <!-- <div class="col-sm-5 mb-2" v-if="reportData.typeReport == 1"><strong>แยกประเภทตามข้อมูล :</strong></div>
                    <div class="col-sm-7" v-if="count_detail.type_report == 1">{{count_detail.type_data_name}}</div>
                    <div class="col-sm-5 mb-2" v-if="reportData.typeReport == 3"><strong>แบ่งตามข้อมูลปัจจุบัน :</strong></div>
                    <div class="col-sm-7" v-if="reportData.typeReport == 3">{{count_detail.data_current_name}}</div>
                    <div class="col-sm-5 mb-2" v-if="count_detail.type_report == 5"><strong>รหัสการสั่งซื้อ :</strong></div>
                    <div class="col-sm-7" v-if="count_detail.type_report == 5">{{count_detail.order_code}}</div> -->
                  </div>
                </div>
                <div class="col-md-5 column">
                  <div class="row">
                    <div class="col-sm-5 mb-2"><strong>ตั้งแต่ :</strong></div>
                    <div class="col-sm-7">{{reportData.dateFromFormat}}</div>
                    <div class="col-sm-5 mb-2"><strong>ถึง :</strong></div>
                    <div class="col-sm-7">{{reportData.dateToFormat}}</div>
                    <div class="col-sm-5 mb-2" v-if="reportData.typeReport == 1"><strong>ร้านค้า :</strong></div>
                    <div class="col-sm-7" v-if="reportData.typeReport == 1">{{reportData.name}}</div>
                    <div class="col-sm-5 mb-2" v-if="reportData.typeReport == 4"><strong>พนักงานขาย :</strong></div>
                    <div class="col-sm-7" v-if="reportData.typeReport == 4">{{reportData.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="pb-4 text-right no_print">
            <b-button size="md"
                      variant="warning"
                      class="style_btn"
                      @click="clickPrint()">
              <i class="fa fa-print"></i> พิมพ์
            </b-button>
          </div>
          <div class="card custom-card">
            <div class="card-body">
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                    <tr class="text-center" >
                      <th>รหัสสินค้า</th>
                      <th>ชื่อสินค้า</th>
                      <th>จำนวน</th>
                      <th>ราคาสินค้า</th>
                      <th>ราคา</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in count_detail" :key="`list_order_detail_${index}`">
                      <td class="text-center">{{data.product_code}}</td>
                      <td class="text-center">{{data.product_name}}</td>
                      <td class="text-center">1</td>
                      <td class="text-right">{{data.cost | number-decimal-format}}</td>
                      <td class="text-right">{{data.price | number-decimal-format}}</td>
                    </tr>
                    <tr v-if="count_detail.length === 0" class="text-center">
                      <td colspan="5">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="float-right pt-5 d-flex">
            <span><router-link :to="'/report'" class="nav-link text-underline no_print"> กลับ</router-link></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateUtils from '../../../util/dateUtils';
  import mock from '../mock/report';
  export default {
    name: 'report-view-page-count-detail',
    data () {
      return {
        permission:{
          appSlug: 'report',
          actionData: {}
        },
        reportData: {},
        id: '',
        mock: mock,
        count_detail: {
          type_report: '',
          type_report_name: '',
          type_time: '',
          type_time_name: '',
          type_data: '',
          type_data_name: '',
          data_current: '',
          data_current_name: '',
          start: '',
          end: '',
          store_id: '',
          store_name: '',
          sale_id: '',
          sale_name: '',
          products: [],
          order_code: ''
        }
      }
    },
    methods: {
      async getCountDetail () {
        const params = `?dateFrom=${this.reportData.dateFrom}&dateTo=${this.reportData.dateTo}
            &storeId=${this.reportData.storeId?this.reportData.storeId:''}&sellerId=${this.reportData.sellerId?this.reportData.sellerId:''}
            &reportType=${this.reportData.typeReport}`;
        const result = await this.HttpServices.getData(`/ab_report/getReportDetail${params}`);
        if(result&&result.status.code=="200"){
          this.count_detail = result.data;
        }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลรายงาน`, ()=>{
          this.$router.push('/report');
        });
        }
      },
      clickPrint () {
        window.print();
      }
    },
    async mounted () {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      const reportData = await this.SessionStorageUtils.getSession("report-data");
      if(reportData){
        this.reportData = JSON.parse(reportData);
        this.reportData.dateFromFormat = DateUtils.dateFormat(DateUtils.toDateFormat(this.reportData.dateFrom, "YYYY-MM-DD"), "DD/MM/YYYY");
        this.reportData.dateToFormat = DateUtils.dateFormat(DateUtils.toDateFormat(this.reportData.dateTo, "YYYY-MM-DD"), "DD/MM/YYYY");
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลรายงาน`, ()=>{
          this.$router.push('/report');
        });
      }
      this.getCountDetail();
    }
  };
</script>
